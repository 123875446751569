<template>
    <CCardBody class="d-flex flex-column">
        <CRow>
            <CCol>				                
                <h1>Success!</h1>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="10">								
                <p class="text-muted">Your password has been changed. <CIcon name="cil-check" color="success" class="text-success" size="lg" /> </p>
                <p>Please proceed to 
                    <CLink style="margin-left: auto" class="mr-1 align-self-center strong" to="login">Login</CLink>   
                </p>
            </CCol>
        </CRow>
    </CCardBody>
</template>

<script>
import router from '@/router';

export default {
    created() {
        setTimeout(() => {
            router.replace("/login");
        }, 4000);
    }
}
</script>
